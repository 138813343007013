import Image from "next/image";
import DeclarationBoxData from "../variables/variables.json";
import { useRouter } from "next/router";
import TranslatedMessage from "./TranslatedMessage";
import classNames from "classname";

import Link from "next/link";

const DeclarationBoxItem = (props) => {
  const router = useRouter();

  // PROPS VARIABLE
  let propsMiasto = props.miasto;
  let propsPodatek = props.podatek;

  let propsType;
  let extendedUrl = "";
  let siteCode = router.query.siteCode;

  // JSON VARIABLE
  let miastoJSON = DeclarationBoxData.miasta[propsMiasto];

  let resources =
    "https://przyjazne-deklaracje.pl/resources/images/icons/general/";

  let declarationLists = DeclarationBoxData.declarationBox.lists[propsPodatek];

  let declarationImageUrl =
    resources + declarationLists.image.url + "/" + declarationLists.image.url;

  let declarationModalAvailable = declarationLists.modal.available;

  let declarationModalData = declarationLists.modal.data;

  let declarationReadMore = declarationLists.readmore.url;

  let miastoUrl = DeclarationBoxData.miasta[propsMiasto].name.url;

  let startUrl;
  let startUrlProd = "https://przyjazne-deklaracje.pl/";
  let startUrlTest = "https://test.przyjazne-deklaracje.pl/";

  if (DeclarationBoxData.miasta[propsMiasto].test) {
    startUrl = startUrlTest;
  } else {
    startUrl = startUrlProd;
  }

  let profileCode = "O4B";
  let templateCode = declarationLists.templateCode;
  let templateScope = DeclarationBoxData.miasta[propsMiasto].templateScope;
  let templateScopeFull = "&templateScope=" + templateScope;

  if (DeclarationBoxData.miasta[propsMiasto].index) {
    propsType = "/";
    templateScopeFull = "";
  } else if (DeclarationBoxData.miasta[propsMiasto].witryna) {
    propsType = "/witryna/";
    extendedUrl = "?siteCode=" + siteCode;
  } else {
    propsType = "/";
  }

  let linkTo =
    startUrl +
    "kalkulatory/menadzer/menadzer-kalkulatorow?profileCode=" +
    profileCode +
    "&templateCode=" +
    templateCode +
    templateScopeFull;

  let ariaLabel = declarationLists.aria.label;

  return (
    <>
      <div className="declaration-box__col">
        <div
          className={classNames("declaration-box", {
            "declaration-box__soon": props.soon,
          })}
          data-a11y-dialog-show={declarationModalData}
          tabIndex={declarationModalAvailable ? 0 : undefined}
        >
          {!props.soon && !declarationModalAvailable && (
            <Link
              className="declaration-box__full-link"
              href={linkTo}
              aria-label={ariaLabel}
              tabIndex="0"
            ></Link>
          )}
          <div className="declaration-box__images">
            <div className="declaration-box__images__img--normal">
              <Image
                src={declarationImageUrl + ".svg"}
                alt=""
                width={68}
                height={68}
                className="declaration-box__images__img"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
            </div>
            <div className="declaration-box__images__img--hover">
              <Image
                src={declarationImageUrl + "-white.svg"}
                alt=""
                width={68}
                height={68}
                className="declaration-box__images__img"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
            </div>
          </div>
          <div className="declaration-box__action">
            <h3 className="declaration-box__heading">
              <TranslatedMessage
                id={"declarationBox.lists." + propsPodatek + ".title"}
              />
            </h3>
            <span className="declaration-box__action__text">
              <TranslatedMessage id="declarationBox.general.action" />
            </span>
            <span className="declaration-box__action__icon"></span>
          </div>
        </div>
        {props.noReadMore ? null : (
          <div className="declaration-box__read-more">
            <Link
              href={propsType + declarationReadMore + extendedUrl}
              aria-label={ariaLabel}
            >
              <TranslatedMessage
                id={"declarationBox.lists." + propsPodatek + ".readmore"}
              />
            </Link>
          </div>
        )}
      </div>

      {/* <button data-a11y-dialog-show="podatek-rolny">Open</button> */}
      {/* <DialogScript /> */}
    </>
  );
};

export default DeclarationBoxItem;
