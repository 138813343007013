import Tippy from "@tippyjs/react";
import Image from "next/image";

const Tooltip = (props) => {
	return (
		<div className="pd-tooltip">
			<Tippy content={props.content}>
				<button className="pd-tooltip__button">
					<Image
						className="pd-wcag--no-visible"
						src="https://przyjazne-deklaracje.pl/resources/images/icons/general/files/tooltip.svg"
						alt=""
						width={24}
						height={24}
					/>
					<Image
						className="pd-wcag--visible"
						src="https://przyjazne-deklaracje.pl/resources/images/icons/general/files/tooltip-green.svg"
						alt=""
						width={24}
						height={24}
					/>
				</button>
			</Tippy>
		</div>
	);
};

export default Tooltip;
